<template>
  <div class="wrapper">
    <i class="iconfont topIcon iconxiangqingon" @click="showPopup"></i>
    <div class="panelInfo">
      <p>
        <span>房间：{{ targetPanelData.room_name }}</span
        ><span>订单号：{{ targetPanelData.order_code }}</span>
      </p>
      <p>
        <span>柜体名称：{{ targetPanelData.cabinet_name }}</span
        ><span>板件名称：{{ targetPanelData.panel_name }}</span>
      </p>
      <p>
        <span>成型尺寸：{{ targetPanelData.panel_size }}</span>
      </p>
    </div>
    <div id="container" class="model" ref="model"></div>
    <div class="handle" ref="handle">
      <div class="slide">
        <div class="text">
          <span>滑动旋转</span>
          <div class="resetICon" @click="resetSlideValue">
            <i
              class="iconfont iconrecovery"
              :class="{ active: showActive }"
              ref="reset"
            ></i>
          </div>
        </div>
        <van-slider
          v-model="value"
          bar-height="4px"
          active-color="#009688"
          @input="onChange"
        />
      </div>
      <van-button
        class="myBtn"
        block
        @click="$router.push({ name: onLine ? 'appScan' : 'scanPage' })"
        >扫码</van-button
      >
      <van-button class="myBtn" block @click="changeModelState">{{
        modelText
      }}</van-button>
    </div>
    <van-popup v-model="showDetail">
      <div class="detail" @click="hidePopup">
        <h2>板件信息</h2>
        <p>房间：{{ targetPanelData.room_name }}</p>
        <p>订单号：{{ targetPanelData.order_code }}</p>
        <p>柜体名称：{{ targetPanelData.cabinet_name }}</p>
        <p>板件名称：{{ targetPanelData.panel_name }}</p>
        <p>成型尺寸：{{ targetPanelData.panel_size }}</p>
        <!-- <van-button  type="info" @click="clearCache">清空缓存（测试用）</van-button> -->
      </div>
    </van-popup>
    <router-view></router-view>
  </div>
</template>

<script>
import axios from "axios";
import * as THREE from "three";
import myDB from "@/assets/js/indexedDB.js";
import myCookie from "@/assets/js/cookies.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
let container;
let camera, scene, renderer;
let mesh;
let cameraControls;
let wireMaterial, gouraudMaterial, highLightMaterial;
let ambientLight, light;
let effectController;
let group;
let box3D;
export default {
  data() {
    return {
      showDetail: false,
      value: 50,
      defaultValue: 50,
      modelText: "线框",
      showActive: false,
      result: [],
      meshList: [],
      materialType: "gouraudMaterial",
      oldGroupPosition: {},
      panelId: "",
      targetPanelData: {
        room_name: "",
        order_code: "",
        cabinet_name: "",
        panel_name: "",
        panel_size: "",
      }, //目标门板数据
      IndexedDB: undefined,
    };
  },

  beforeRouteUpdate(to, from, next) {
    if (from.name === "scanPage" || from.name === "appScan") {
      let ScanedUrl = localStorage.getItem("ScanedUrl");
      if (ScanedUrl) {
        this.meshList = []; //清空mesh列表
        group.children = []; //清空group已添加的mesh
        let panelId = ScanedUrl.split("=")[1];
        this.$toast(panelId);
        if (panelId) {
          this.requireJson(panelId);
        } else {
          this.$toast.fail({
            message: "二维码无效！",
          });
        }
        localStorage.removeItem("ScanedUrl");
      }
    }
    next();
  },

  async mounted() {
    //url带panelId
    let panelId = this.$route.query.i;
    //初始化indexedDB
    this.IndexedDB = await myDB.openDB("modelData", 1, {
      name: "model",
      keyPath: "panelId",
    });
    this.checkCookie();
    this.initSence();
    this.requireJson(panelId);
  },
  methods: {
    initSence: function () {
      const canvasWidth = this.$refs.model.offsetWidth;
      const canvasHeight = this.$refs.model.offsetHeight;
      const materialColor = new THREE.Color();
      container = this.$refs.model;
      // CAMERA
      camera = new THREE.PerspectiveCamera(
        45,
        canvasWidth / canvasHeight,
        1,
        80000
      );
      camera.position.set(0, -5000, 2000); //设置相机默认位置camera.position.set(0, -5000, 2000)
      materialColor.setRGB(1.0, 1.0, 1.0);
      renderer = new THREE.WebGLRenderer({ antialias: true }); //抗锯齿
      // renderer.shadowMap.enabled = true;
      // renderer.shadowMap.type = THREE.PCFSoftShadowMap; //阴影类型
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(canvasWidth, canvasHeight);
      container.appendChild(renderer.domElement);
      // EVENTS
      window.addEventListener("resize", this.onWindowResize, false);
      // 控制
      cameraControls = new OrbitControls(camera, renderer.domElement); //轨道控制器(可以使得相机围绕目标进行轨道运动)
      cameraControls.enableRotate = true; //开启上下左右旋转
      // console.log(THREE.TOUCH)
      cameraControls.touches = {
        //触碰事件
        ONE: THREE.TOUCH.PAN,
        TWO: THREE.TOUCH.DOLLY_PAN,
      };
      cameraControls.addEventListener("change", this.render);
      // 材质
      wireMaterial = new THREE.MeshBasicMaterial({
        color: 0x000000,
        // wireframe: true, //几何体渲染为线框
        depthTest: false,
      });
      gouraudMaterial = new THREE.MeshLambertMaterial({
        //非光泽表面的材质
        color: materialColor,
        //  transparent: true,
        //  opacity: 0.4,
      });
      highLightMaterial = new THREE.MeshBasicMaterial({
        color: 0xffff00,
        // side: THREE.DoubleSide,
        // depthTest: false,
        // transparent: true
      });
      // 灯光
      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444); //半球光（光源直接放置于场景之上）
      hemiLight.position.set(0, 20, 0);
      ambientLight = new THREE.AmbientLight(0x333333); //环境光会均匀的照亮场景中的所有物体
      light = new THREE.DirectionalLight(0xffffff, 1.0); //平行光(可以投射阴影)
      // light.castShadow = true;
      // light.shadowCameraVisible=true;
      light.position.set(-3, 10, -10); //光线将会从上往下照射。
      light.shadow.camera.top = 2; //camera生成场景的深度图
      light.shadow.camera.bottom = -2;
      light.shadow.camera.left = -2;
      light.shadow.camera.right = 2;
      light.shadow.camera.near = 0.1;
      light.shadow.camera.far = 40;
      scene = new THREE.Scene();
      group = new THREE.Group();
      // scene.background = new THREE.Color(0xf2f2f2);
      scene.add(hemiLight);
      scene.add(ambientLight);
      scene.add(light);
      // GUI
      this.setupGui();
    },

    //请求oss
    requireJson: async function (panelId) {
      try {
        this.$toast.loading({
          message: "加载中",
          duration: 0,
        });
        //读取json文件夹下的所有json文件
        panelId = panelId
          ? panelId
          : "Panel_852099_821d45fb-563e-4c46-b8c3-9cdcbd0c97a3";
        this.panelId = panelId;
        let listData = await axios.get(
          `${this.Host}/data/${panelId}/list.json`
        );
        this.result = await this.loopRequestJsonData(
          listData.data.list,
          panelId
        );
        //循环加载mesh
        await this.result.forEach((item, index) => {
          if (item.unique_code === this.panelId) {
            if (item.panel_info) {
              this.targetPanelData = item.panel_info;
            }
          }
          myDB.addData(this.IndexedDB, "model", {
            panelId: item.unique_code,
            addedTime: new Date(),
            modelData: item,
          }); //根据板件id存储对应数据到indexDB
          this.loadMesh(item, index);
        });
        this.meshList.forEach((mitem) => {
          group.add(mitem);
        });
        //新建包围盒
        this.initMeshLocation();
        this.$toast.clear();
        //执行渲染
        this.render();
      } catch (err) {
        console.log(err);
        if (err) {
          this.$toast.fail({
            message: "板件Id无效",
          });
        }
      }
    },

    //循环请求获取json数据
    loopRequestJsonData: function (data) {
      return new Promise((resolve) => {
        let reqList = [];
        let resList = [];
        let startTime = new Date().getTime();
        data.forEach(async (item) => {
          let panelId = item.id;
          let panelData = await myDB.read(this.IndexedDB, "model", panelId);
          let FolderURL = "";
          if (process.env.NODE_ENV === "production") {
            if (this.onLine) {
              FolderURL = this.Host + item.url;
            } else {
              FolderURL = item.url;
            }
          } else {
            if (this.testLine) {
              FolderURL = this.Host + item.url;
            } else {
              FolderURL = `/data/${this.panelId}/${item.id}.json`;
            }
          }
          if (panelData) {
            resList.push(panelData.modelData);
          } else {
            reqList.push(axios.get(FolderURL));
          }
          if (reqList.length + resList.length === data.length) {
            if (reqList.length > 0) {
              let dataList = Promise.all(reqList);
              dataList.then((res) => {
                res.forEach((item) => {
                  resList.push(item.data);
                });
                let endTime = new Date().getTime();
                console.log(
                  "请求时间（线上）:" + (endTime - startTime) / 1000 + "s"
                );
                resolve(resList);
              });
            } else {
              let endTime = new Date().getTime();
              console.log(
                "请求时间（缓存）:" + (endTime - startTime) / 1000 + "s"
              );
              resolve(resList);
            }
          }
        });
      });
    },

    //初始化物体位置（居中）
    initMeshLocation: function () {
      //新建包围盒
      const axis = new THREE.Vector3(0, 0, 1);
      const radian = (2 * Math.PI) / 100;
      group.setRotationFromAxisAngle(axis, radian * (this.value + 50)); //三维向量、弧度
      box3D = new THREE.Box3();
      box3D.setFromObject(group);
      let newMatrix = new THREE.Matrix4();
      let centerSpot = box3D.getCenter();
      newMatrix.makeTranslation(-centerSpot.x, -centerSpot.y, -centerSpot.z);
      group.applyMatrix4(newMatrix);
      group.updateMatrix();
    },

    setupGui: function () {
      effectController = {
        shininess: 40.0,
        ka: 0.17,
        kd: 0.51,
        ks: 0.2,
        metallic: true,
        hue: 0.121,
        saturation: 0.73,
        lightness: 0.66,
        lhue: 0.04,
        lsaturation: 0.01, // non-zero so that fractions will be shown
        llightness: 1.0,
        lx: 0.32,
        ly: 0.39,
        lz: 0.7,
        newTess: 15,
        bottom: true,
        lid: true,
        body: true,
        fitLid: false,
        nonblinn: false,
        newShading: "wireframe",
      };
    },

    loadMesh: function (jsonData) {
      if (!jsonData.meshList) {
        this.$toast(`${jsonData.panel_info.panel_name}数据为空！`);
        return;
      }
      let totalNum = 0;
      let vertsList = [];
      let normalsList = [];
      jsonData.meshList.forEach((item) => {
        totalNum += item.faces.length; //面的数量
        vertsList = vertsList.concat(item.verts); //verts每块板件的位置坐标
        normalsList = normalsList.concat(item.normals); //normals面的法向量
      });
      const triangles = totalNum;
      let geometry = new THREE.BufferGeometry(); //几何体是面片、线或点几何体的有效表述(点位置，面片索引、法相量、颜色值、UV 坐标)
      const positions = new Float32Array(triangles * 3 * 3); //一个三角形3个顶点，每个顶点(x,y,z)
      const normals = new Float32Array(triangles * 3 * 3);
      for (let i = 0; i < positions.length; i += 9) {
        let b = i / 3;
        positions[i] = vertsList[b].x;
        positions[i + 1] = vertsList[b].y;
        positions[i + 2] = vertsList[b].z;

        positions[i + 3] = vertsList[b + 1].x;
        positions[i + 4] = vertsList[b + 1].y;
        positions[i + 5] = vertsList[b + 1].z; //物体坐标位置

        positions[i + 6] = vertsList[b + 2].x;
        positions[i + 7] = vertsList[b + 2].y;
        positions[i + 8] = vertsList[b + 2].z;

        normals[i] = normalsList[b].x;
        normals[i + 1] = normalsList[b].y;
        normals[i + 2] = normalsList[b].z;

        normals[i + 3] = normalsList[b + 1].x;
        normals[i + 4] = normalsList[b + 1].y; //物体发向量坐标位置
        normals[i + 5] = normalsList[b + 1].z;

        normals[i + 6] = normalsList[b + 2].x;
        normals[i + 7] = normalsList[b + 2].y;
        normals[i + 8] = normalsList[b + 2].z;
      }
      geometry.setAttribute(
        //为当前几何体设置一个 attribute 属性
        "position",
        new THREE.BufferAttribute(positions, 3)
      );
      geometry.setAttribute("normal", new THREE.BufferAttribute(normals, 3)); //itemSize队列中与顶点相关的数据值的大小（值为3）
      geometry.computeBoundingSphere();
      // wireMaterial = new THREE.MeshBasicMaterial({
      //   color: 0x000000,
      //   depthTest: false,
      // });
      //  highLightMaterial = new THREE.MeshBasicMaterial({
      //   color: 0xffff00
      // });
      let material =
        this.panelId === jsonData.unique_code
          ? highLightMaterial
          : this.materialType == "wireMaterial"
          ? wireMaterial
          : gouraudMaterial;
      mesh = new THREE.Mesh(geometry, material);
      if (this.materialType === "wireMaterial") {
        let edges = new THREE.EdgesGeometry(geometry);
        // 立方体线框，不显示中间的斜线
        let edgesMaterial = new THREE.LineBasicMaterial({
          color: 0xffffff,
        });
        let line = new THREE.LineSegments(edges, edgesMaterial);
        mesh.add(line);
      }
      //添加辅助线
      if (this.materialType === "gouraudMaterial") {
        let edgesMtl = new THREE.LineBasicMaterial({
          color: 0xeeeeee,
          side: THREE.DoubleSide,
          //   depthTest:  this.panelId === jsonData.unique_code?false:true
        });
        let cubeEdges = new THREE.EdgesGeometry(geometry, 1);
        let cubeLine = new THREE.LineSegments(cubeEdges, edgesMtl);
        mesh.add(cubeLine);
      }
      let matrixObj = jsonData.meshInstanceList[0].mesh_to_world; //（整个物体）世界坐标
      let matrix = this.MakeMatrix4FromSoftware(matrixObj);
      mesh.applyMatrix4(matrix); //更新物体的位置、旋转和缩放
      mesh.receiveShadow = true;
      this.meshList.push(mesh);
    },

    render: function () {
      scene.add(group);
      ambientLight.color.setHSL(
        //(hsl)色相值、饱和度、亮度值
        effectController.hue,
        effectController.saturation,
        effectController.lightness * effectController.ka
      );
      light.position.set(
        effectController.lx,
        effectController.ly,
        effectController.lz
      );
      light.color.setHSL(
        effectController.lhue,
        effectController.lsaturation,
        effectController.llightness
      );
      // scene.background = null;

      renderer.render(scene, camera);
    },

    //坐标
    MakeMatrix4FromSoftware: function (array) {
      let matrix4 = new THREE.Matrix4();
      matrix4.set(
        array[0].x,
        array[1].x,
        array[2].x,
        array[3].x,
        array[0].y,
        array[1].y,
        array[2].y,
        array[3].y,
        array[0].z,
        array[1].z,
        array[2].z,
        array[3].z,
        array[0].w,
        array[1].w,
        array[2].w,
        array[3].w
      );
      return matrix4;
    },

    onWindowResize: function () {
      setTimeout(() => {
        const canvasWidth = window.innerWidth;
        const canvasHeight =
          window.innerHeight - this.$refs.handle.offsetHeight;
        camera.aspect = canvasWidth / canvasHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(canvasWidth, canvasHeight);
      }, 500);
    },

    //slide滚动条滚动
    onChange(value) {
      const axis = new THREE.Vector3(0, 0, 1);
      const radian = (2 * Math.PI) / 100;
      let box3D = new THREE.Box3();
      group.setRotationFromAxisAngle(axis, radian * (value + 50)); //三维向量、弧度
      box3D.setFromObject(group); //用来计算包围盒的3D对象
      let newMatrix = new THREE.Matrix4();
      let centerSpot = box3D.getCenter();
      newMatrix.makeTranslation(-centerSpot.x, -centerSpot.y, -centerSpot.z);
      group.applyMatrix4(newMatrix);
      // group.updateMatrixWorld();
      this.render();
    },

    //重置slide值
    resetSlideValue: function () {
      this.showActive = true;
      setTimeout(() => {
        this.showActive = false;
      }, 500);
      this.value = this.defaultValue;
      this.onChange(this.defaultValue);
    },

    //改变模型形态
    changeModelState: async function () {
      this.meshList = []; //清空mesh列表
      group.children = []; //清空group已添加的mesh
      if (this.materialType === "wireMaterial") {
        this.materialType = "gouraudMaterial";
        this.modelText = "线框";
      } else {
        this.materialType = "wireMaterial";
        this.modelText = "实体";
      }
      await this.result.forEach((item, index) => {
        this.loadMesh(item, index);
      });
      this.meshList.forEach((mitem) => {
        group.add(mitem);
      });
      this.onChange(this.value);
      // //执行渲染
      this.render();
    },

    //清空indexedDB
    clearCache: async function () {
      let result = await myDB.clearStoreData(this.IndexedDB, "model");
      if (result) {
        console.log("清空缓存成功");
        myCookie.setCookie("loadedTime", new Date()); //重新设置登录时间，有效期30天
      }
    },

    //检查cookie登录时间
    checkCookie: function () {
      let loadedTime = myCookie.getCookie("loadedTime");
      //为空（已过期），执行清空indexedDB操作
      if (!loadedTime) {
        this.clearCache();
      }
    },

    //展示弹层
    showPopup: function () {
      this.showDetail = true;
    },

    //隐藏弹层
    hidePopup: function () {
      this.showDetail = false;
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  .topIcon {
    display: inline-block;
    padding: 25px;
    font-size: 46px;
    color: #ffffff;
    position: absolute;
  }
  .panelInfo {
    width: 100%;
    color: #ffffff;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 28px;
    padding: 15px 15px 0 20px;
    box-sizing: border-box;
    p {
      display: flex;
      flex-direction: row;
      span {
        height: 40px;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span:not(:last-child) {
        margin-right: 8px;
      }
    }
    p:not(:last-child) {
      margin-bottom: 4px;
    }
  }
  .iconorder_icon {
    left: 0;
    top: 0;
  }
  .iconxiangqingon {
    right: 0;
    top: 0;
    z-index: 10;
  }
  .model {
    flex: 1;
    box-sizing: border-box;
  }
  .handle {
    background-color: #000000;
    padding: 10px 5% 25px;
    box-sizing: border-box;
    .slide {
      color: #ffffff;
      margin-bottom: 50px;
      .text {
        height: 50px;
        font-size: 30px;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .resetICon {
          padding: 4px 6px 4px 20px;
        }
        i {
          display: inline-block;
          transform: rotate(0deg);
          transition: all 1s ease;
          @keyframes myRotate {
            from {
              transform: rotate(0);
            }
            to {
              transform: rotate(360deg);
            }
          }
          &.active {
            animation: myRotate 1s ease;
          }
        }
      }
    }
    .myBtn {
      font-size: 30px;
      color: #ffffff;
      border-radius: 20px;
      background-color: #009688;
      border-color: #81dad2;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  /deep/.van-popup {
    width: 75%;
    .detail {
      color: #222222;
      padding: 25px 50px;
      h2 {
        text-align: left;
        padding: 10px 0 20px;
      }
      p {
        font-size: 30px;
        line-height: 50px;
        text-align: left;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>

