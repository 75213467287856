export default {
  //设置cookie（默认30天有效期）
  setCookie: function (name, value, days = 30) {
    let exp = new Date();
    exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
  },
  //获取cookie
  getCookie: function (name) {
    let cookies = document.cookie;
    let arr = cookies.split("; ");
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].split("=")[0] == name) {
        return arr[i].split("=")[1];
      }
    }
  },
  //移除cookie
  removeCookie: function (name) {
    this.setCookie(name, "", -1);
  }

}